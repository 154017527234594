<template>
  <div>
    <!-- NOTE - Modal exchange ticket amount-->
    <b-modal
      id="modal-modify-eticket-exchange-ticket"
      :title="$t('reservation.changeTicket')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      no-close-on-backdrop
      size="lg"
      @show="showModalExchangeTicket"
    >
      <template #modal-footer="{close}">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-secondary"
          class="center rounded-pill width-100"
          @click="close()"
        >
          {{ $t('reservation.close') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient mt-lg-25  border-0"
          pill
          :disabled="!(passengerSelected && passengerSelected.length) || !(segmentSelected && segmentSelected.length)"
          @click="submitCheckPriceAmount"
        >
          <span class="align-middle">{{
            $t('reservation.historyActions.CALCULATE_PRICE')
          }}</span>
        </b-button>
      </template>

      <!-- ANCHOR - Chọn hành khách -->
      <b-card>
        <div class="font-weight-bold mr-1 mb-1 text-dark font-small-4">
          Chọn số vé:
        </div>

        <b-form-checkbox-group
          v-if="!isEmpty(paxsData)"
          id="checkbox-group-2"
          v-model="passengerSelected"
          name="flavour-2"
          stacked
          class="margin-checkbox-label"
        >
          <b-form-checkbox
            v-for="(passenger, index) in paxsData"
            :key="index"
            :value="passenger"
            class="mb-25"
          >
            <div class="text-dark">
              <span class="text-warning font-weight-bolder">{{ `${passenger.number} ` }}</span>
              <small class="mr-25 font-weight-bolder">({{ passenger.paxId }})</small>
              <span class="mr-50 font-weight-bolder">{{ `${passenger.lastName}/${passenger.firstName}` }}</span>
              <span class="text-dark font-weight-bold">{{ resolveSegmentsInEticket(passenger.segments) }}</span>

            </div>
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-card>

      <!-- ANCHOR - Chọn hành trình -->
      <b-card>
        <div class="font-weight-bold mr-1 mb-1 font-small-4">
          {{ $t('reservation.chooseSegment') }}:
        </div>

        <b-form-checkbox-group
          v-if="!isEmpty(segmentArray)"
          id="checkbox-group-2"
          v-model="segmentSelected"
          name="flavour-2"
          stacked
          class="margin-checkbox-label"
        >
          <b-form-checkbox
            v-for="(segment, index) in segmentArray"
            :key="index"
            :value="segment.segmentId"
            class="mb-25"
          >
            <div class="font-weight-bolder text-dark">
              <!-- <span class="mr-50">{{ segment.devSegmentIndex }}</span>
              <span class="mr-50">{{ segment.airline }}{{ segment.flightNumber }}</span>
              <span class="mr-50">{{ segment.bookingClass }}</span>
              <span class="mr-50">{{ convertISODateTime(segment.departure.at, segment.departure.timezone).dayMonth }}</span>
              <span class="mr-50">{{ segment.departure.iataCode }}{{ segment.arrival.iataCode }}</span>
              <span class="mr-50">{{ segment.status }}</span>
              <span class="">{{ convertISODateTime(segment.departure.at, segment.departure.timezone).hourMin }} {{ convertISODateTime(segment.arrival.at, segment.arrival.timezone).hourMin }}</span> -->
              {{ convertShortTrip(segment) }}
            </div>
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-card>

      <div class="mt-1">
        <b-alert
          show
          variant="warning"
        >
          <div class="alert-body">
            Bạn phải chắc chắn nắm được điều kiện đổi vé. Các trường hợp không
            chắc chắn hoặc vé có mua các dịch vụ kèm vui lòng liên hệ Booker.
          </div>
        </b-alert>
      </div>
    </b-modal>

    <!-- NOTE - Modal CONFIRM exchange ticket -->
    <b-modal
      id="modal-modify-eticket-exchange-ticket-price"
      :title="$t('reservation.confirm')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      body-class="px-25"
      centered
      size="lg"
      no-close-on-backdrop
      @hide="handleHideModalExchangeTicketPrice"
    >
      <template #modal-footer="{close}">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-danger"
          class="center rounded-pill"
          @click="close()"
        >
          {{ $t('reservation.back') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient mt-lg-25  border-0"
          pill
          :disabled="!isConfirm || !hasPrice"
          @click="submitExchangeTicket"
        >
          <span class="align-middle">
            {{ $t('reservation.changeTicket') }}
          </span>
        </b-button>
      </template>

      <div v-if="priceExchange && priceExchange.subAmount < 0">
        <b-card
          no-body
        >
          <b-alert
            class="mb-0 px-1 py-50"
            variant="warning"
            show
          >
            Không đủ điều kiện xuất đổi vé, kiểm tra lại thông tin vé, chặng bay đã chọn.
          </b-alert>
        </b-card>
      </div>

      <div v-else>
        <b-card
          body-class="p-75"
        >
          <p class="text-dark font-weight-bolder">
            Vui lòng kiểm tra lại thông tin hành trình trước khi thay đổi
          </p>
          <div class="mb-1">
            <p class="text-danger font-weight-bolder font-medium-4 mb-50">
              Hành trình cũ
            </p>
            <b-table
              v-if="priceExchange && priceExchange.exchFlights"
              bordered
              responsive
              small
              class="border-danger rounded-lg"
              :items="priceExchange.exchFlights"
              :fields="['seg', 'number', 'class', 'flight', 'date', 'fareBasic', 'status','totalTax', 'netPrice']"
              thead-tr-class="text-nowrap text-center"
              tbody-tr-class="text-center"
            >
              <template #cell(seg)="data">
                <div>
                  {{ data.index + 1 }}
                </div>
              </template>
              <template #cell(number)="{item}">
                <div class="text-nowrap">
                  {{ item.operating }}{{ item.flightNumber }}
                </div>
              </template>
              <template #cell(class)="{item}">
                <div>
                  {{ item.bookingClass }}
                </div>
              </template>
              <template #cell(flight)="{item}">
                <div class="text-nowrap">
                  {{ item.departure }}-{{ item.arrival }}
                </div>
              </template>
              <template #cell(date)="{item}">
                <div>
                  {{ convertISODateTime(item.departureDate).dateTime }}
                </div>
              </template>
              <template #cell(fareBasic)="{item}">
                <div>
                  {{ item.fareBasic }}
                </div>
              </template>
              <template #cell(status)="{item}">
                <div>
                  {{ resolveStatusByFlown(item.flown) }}
                </div>
              </template>
              <template #cell(totalTax)="{item}">
                <div>
                  {{ formatCurrency(item.totalTax) }}
                </div>
              </template>
              <template #cell(netPrice)="{item}">
                <div>
                  {{ formatCurrency(item.netPrice) }}
                </div>
              </template>
            </b-table>
          </div>

          <div class="mb-2 mt-1">
            <p class="text-success font-weight-bolder font-medium-4 mb-50">
              Hành trình mới
            </p>
            <b-table
              v-if="priceExchange && priceExchange.newFlights"
              bordered
              responsive
              small
              class="border-success rounded-lg"
              :items="priceExchange.newFlights"
              :fields="['seg', 'number', 'class', 'flight', 'date', 'fareBasic', 'status', 'totalTax', 'netPrice']"
              thead-tr-class="text-nowrap text-center"
              tbody-tr-class="text-center"
            >
              <template #cell(seg)="data">
                <div>
                  {{ data.index + 1 }}
                </div>
              </template>
              <template #cell(number)="{item}">
                <div class="text-nowrap">
                  {{ item.operating }}{{ item.flightNumber }}
                </div>
              </template>
              <template #cell(class)="{item}">
                <div>
                  {{ item.bookingClass }}
                </div>
              </template>
              <template #cell(flight)="{item}">
                <div class="text-nowrap">
                  {{ item.departure }}-{{ item.arrival }}
                </div>
              </template>
              <template #cell(date)="{item}">
                <div>
                  {{ convertISODateTime(item.departureDate, 'UTC').dateTime }}
                </div>
              </template>
              <template #cell(fareBasic)="{item}">
                <div>
                  {{ item.fareBasic }}
                </div>
              </template>
              <template #cell(status)="{item}">
                <div>
                  {{ resolveStatusByFlown(item.flown) }}
                </div>
              </template>
              <template #cell(totalTax)="{item}">
                <div>
                  {{ formatCurrency(item.totalTax) }}
                </div>
              </template>
              <template #cell(netPrice)="{item}">
                <div>
                  {{ formatCurrency(item.netPrice) }}
                </div>
              </template>
            </b-table>
          </div>

          <div>
            <b-row
              class="mb-sm-1 mb-md-25 justify-content-end"
              :class="isMobileView ? 'flex-nowrap' : ''"
            >
              <b-col
                cols="8"
                md="6"
                class="text-right"
              >
                Tổng tiền chênh:
              </b-col>
              <b-col
                md="4"
                class="text-right"
              >
                <span
                  v-if="priceExchange && priceExchange.subAmount >= 0"
                  class="font-weight-bold"
                >
                  {{ formatterAmount(priceExchange.subAmount) }}
                </span>
                <span v-else>
                  <b-spinner small />
                </span>
              </b-col>
            </b-row>
            <b-row
              class="mb-sm-1 mb-md-25 justify-content-end"
              :class="isMobileView ? 'flex-nowrap' : ''"
            >
              <b-col
                cols="8"
                md="6"
                class="text-right"
              >
                Phí đổi:
              </b-col>
              <b-col
                md="4"
                class="text-right"
              >
                <span
                  v-if="priceExchange && (priceExchange.amount >= 0)"
                  class="font-weight-bold"
                >
                  {{ getTotalExchangeAmount(priceExchange) || 0 }}
                </span>
                <span v-else>
                  <b-spinner small />
                </span>
              </b-col>
            </b-row>
            <b-row
              class="mt-sm-1 mt-md-25 justify-content-end"
              :class="isMobileView ? 'flex-nowrap' : ''"
            >
              <b-col
                cols="8"
                md="6"
                class="font-weight-bolder text-dark text-right"
              >
                Tổng tiền phải trả:
              </b-col>
              <b-col
                md="4"
                class="text-right"
              >
                <span
                  v-if="priceExchange && priceExchange.amount >= 0"
                  class="font-weight-bolder"
                >
                  {{ formatterAmount(priceExchange.amount) || 0 }}
                </span>
                <span v-else>
                  <b-spinner small />
                </span>
              </b-col>
            </b-row>
          </div>
        </b-card>

        <IAmConfirm
          v-if="priceExchange"
          :is-confirm.sync="isConfirm"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BAlert,
  BCard,
  BButton,
  BRow,
  BCol,
  BTable,
  BSpinner,
  BFormCheckboxGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import {
  ref,
  toRefs,
  // eslint-disable-next-line no-unused-vars
  watch,
  // computed,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import capitalize from 'lodash/capitalize'

import { formatCurrency, convertISODateTime, convertShortTrip } from '@/@core/utils/filter'
import store from '@/store'
import IAmConfirm from '@/components/IAmConfirm.vue'

import useReservationHandle from '@reservation/useReservationHandle'

import useToast from '@useToast'

export default {
  components: {
    BModal,
    BAlert,
    BCard,
    BButton,
    BRow,
    BCol,
    BTable,
    BSpinner,
    BFormCheckboxGroup,
    BFormCheckbox,
    IAmConfirm,
  },
  props: {
    reservationsData: {
      type: Object,
      required: true,
    },
    paxsData: {
      type: Array,
      required: true,
    },
    // tktData: {
    //   type: Object,
    //   required: true,
    // },
  },
  setup(props, { root }) {
    const { toastError } = useToast()

    const bookingData = toRefs(props).reservationsData
    const priceExchange = ref()
    const isConfirm = ref(false)
    const hasPrice = ref(false)
    const segmentArray = ref([])
    // const passengerArray = ref([])
    const segmentSelected = ref([])
    const passengerSelected = ref([])
    const defaultPassenger = ref(null)

    const segmentToDisabled = ref([])

    const {
      exchangeTicketAmount,
      exchangeTickets,
    } = useReservationHandle()

    function resolveSegmentsInEticket(segments) {
      const result = []

      if (!isEmpty(segments)) {
        segments.forEach(item => {
          result.push(`${convertISODateTime(item.departureDate, 'UTC').dayMonth}-${item.departure}${item.arrival}`)
        })
      }

      const dataReturn = !isEmpty(result) ? result.join(', ') : ''

      return dataReturn
    }

    // function resolveListTkt(paxLists) {
    //   const result = []
    //   const listPassengerHasTkt = paxLists.filter(p => !isEmpty(p.eticket)
    //   && p.eticket.some(ticket => (ticket.ticketType === 'TKT')
    //   && (ticket.status === 'OK')
    //   && (!isEmpty(ticket.segments) && !ticket.segments.some(seg => ['P', 'A', 'G'].includes(seg.bookingClass)))))

    //   if (!isEmpty(listPassengerHasTkt)) {
    //     const dataPaxMap = listPassengerHasTkt.map(p => ({
    //       ...p,
    //       eticket: p.eticket.filter(ticket => (ticket.ticketType === 'TKT') && (ticket.status === 'OK')).map(t => ({
    //         ...t,
    //         paxId: p.paxId,
    //         firstName: p.firstName,
    //         lastName: p.lastName,
    //         paxType: p.paxType,
    //       })),
    //     }))

    //     dataPaxMap.forEach(passenger => {
    //       passenger.eticket.forEach(eticket => {
    //         result.push(eticket)
    //       })
    //     })
    //   }

    //   return result
    // }

    function showModalExchangeTicket() {
      segmentArray.value = []
      segmentSelected.value = []

      // passengerArray.value = []
      passengerSelected.value = []

      const flatSegment = bookingData.value.itineraries.flat()

      segmentArray.value = isEmpty(flatSegment) ? [] : flatSegment.filter(seg => !['P', 'A', 'G'].includes(seg.bookingClass))

      // passengerArray.value = bookingData.value.paxLists.filter(item => !isEmpty(item.eticket) && item.eticket.some(ticket => (ticket.ticketType === 'TKT') && (ticket.status === 'OK')))
      // passengerArray.value = resolveListTkt(bookingData.value.paxLists)
    }

    // CHECK PRICE ===========================================
    function mergeItems(items) {
      const flatSegment = bookingData.value.itineraries.flat()

      const mergedItem = {
        amount: 0,
        approvalCode: items[0].approvalCode,
        exchFlights: [],
        newFlights: [],
        subAmount: 0,
      }

      items.forEach(item => {
        const newFlightsArr = item.newFlights.map(seg => {
          let departureDate = seg.departureDate
          const findTripArr = flatSegment
            .filter(s => s.arrival.iataCode === seg.arrival
              && s.departure.iataCode === seg.departure
              && s.bookingClass === seg.bookingClass
              && s.flightNumber === seg.flightNumber)

          if (findTripArr.length === 1) { // FIXME : khi hiển thị lấy timeZone = 'UTC' => trường đã fix lại 26/12/2023
            departureDate = convertISODateTime(findTripArr[0].departure.at, findTripArr[0].departure.timeZone).ISOdatetime
          } else if (findTripArr.length > 1) {
            const findByTime = findTripArr
              .find(sg => sg.departure.at.split('T')[0] === seg.departureDate.split('T')[0])

            if (findByTime) { // FIXME : khi hiển thị lấy timeZone = 'UTC' => trường đã fix lại 26/12/2023
              departureDate = convertISODateTime(findByTime.departure.at, findByTime.departure.timeZone).ISOdatetime
            }
          }

          return {
            ...seg,
            departureDate,
          }
        })

        mergedItem.amount += item.amount
        mergedItem.exchFlights.push(...item.exchFlights)
        mergedItem.newFlights.push(...newFlightsArr)
        mergedItem.subAmount += item.subAmount
      })

      return mergedItem
    }

    function submitCheckPriceAmount() {
      const payload = {
        source: bookingData.value.source,
        pnrNumber: bookingData.value.bookingCode,
        paxId: passengerSelected.value[0].paxId, // props.ticketData.passengerPaxId, // FIXME - ??? id của thằng nào?
        segmentIds: segmentSelected.value,
        exchangeTickets: passengerSelected.value.map(p => ({
          paxId: p.paxId,
          ticketNumber: p.number,
        })),
      }

      priceExchange.value = true
      store.dispatch('app/setLoading', true)

      exchangeTicketAmount(payload)
        .then(res => {
          priceExchange.value = mergeItems(res)
          hasPrice.value = true
          this.$bvModal.show('modal-modify-eticket-exchange-ticket-price')
        })
        .catch(error => {
          console.error(error)
          toastError({
            title: 'messagesList.error',
            content: 'Lỗi lấy giá đổi vé, vui lòng thử lại!',
          })
        })
        .finally(() => {
          store.dispatch('app/setLoading', false)
        })
    }

    function formatterAmount(amount) {
      if (amount >= 0) return formatCurrency(amount) || amount
      return formatCurrency(amount * -1) || (amount * -1)
    }

    function getTotalExchangeAmount(data) {
      const totalExchange = data.amount - data.subAmount
      if (totalExchange >= 0) return formatCurrency(totalExchange) || totalExchange
      return formatCurrency(totalExchange * -1) || (totalExchange * -1)
    }

    const FLOWN_CODE = {
      R: 'RFD',
      E: 'EXCH',
      U: 'OK',
      F: 'USED',
      S: 'SC',
    }

    const resolveStatusByFlown = flown => FLOWN_CODE[flown] || flown

    function handleHideModalExchangeTicketPrice() {
      isConfirm.value = false
      hasPrice.value = false
    }

    // EXCHANGE ===========================================

    function submitExchangeTicket() {
      const payload = {
        source: bookingData.value.source,
        pnrNumber: bookingData.value.bookingCode,
        agencyCode: bookingData.value.agency,
        paxCode: bookingData.value.paxContact.code,
        segmentIds: segmentSelected.value,
        exchangeTickets: passengerSelected.value.map(p => ({
          paxId: p.paxId,
          ticketNumber: p.number,
        })),
      }

      store.dispatch('app/setLoading', true)

      exchangeTickets(payload)
        .then(() => {
          Array.from(window.document.querySelectorAll('.modal.show'))
            .map(el => el.id)
            .forEach(id => {
              root.$root.$emit('bv::hide::modal', id)
            })
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          store.dispatch('app/setLoading', false)
        })
    }

    return {
      hasPrice,
      isConfirm,
      bookingData,
      segmentArray,
      // passengerArray,
      priceExchange,
      segmentSelected,
      passengerSelected,
      segmentToDisabled,
      defaultPassenger,

      isEmpty,
      capitalize,
      convertShortTrip,
      formatCurrency,
      formatterAmount,
      convertISODateTime,
      submitExchangeTicket,
      resolveStatusByFlown,
      submitCheckPriceAmount,
      getTotalExchangeAmount,
      showModalExchangeTicket,
      handleHideModalExchangeTicketPrice,

      resolveSegmentsInEticket,
    }
  },
}
</script>

<style lang="scss" scoped>
.margin-checkbox-label::v-deep {
  .custom-control-label {
    margin-top: 2px !important;
  }
}
</style>
