<template>
  <div>
    <!-- NOTE - Modal refund ticket amount-->
    <b-modal
      id="modal-modify-eticket-refund-ticket"
      :title="$t('reservation.refundTicket')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      no-close-on-backdrop
      size="lg"
      @show="showModalRefundTicket"
    >
      <template #modal-footer="{close}">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-secondary"
          class="center rounded-pill width-100"
          @click="close()"
        >
          {{ $t('reservation.close') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient mt-lg-25  border-0"
          pill
          :disabled="isEmpty(indexSelectedTicketToRefund)"
          @click="submitCheckPriceAmount"
        >
          <span class="align-middle">{{
            $t('reservation.historyActions.CALCULATE_PRICE')
          }}</span>
        </b-button>
      </template>

      <!-- ANCHOR - Chọn số vé -->
      <b-card
        :body-class="isMobileView ? 'px-50' : ''"
      >
        <div class="font-weight-bold mr-1 mb-1 text-dark">
          Chọn số vé:
        </div>

        <div class="custom-label-checkbox">
          <b-form-group>
            <template #label>
              <b-form-checkbox
                v-if="listPaxsData.length > 1"
                v-model="allSelected"
                aria-describedby="flights"
                aria-controls="flights"
                class="py-0 pt-1"
                @change="toggleAll"
              >
                <div
                  class="text-body-2 font-weight-bold"
                  style="padding-bottom: 13px"
                >
                  {{ allSelected ? $t('reservation.unselectAll') : $t('reservation.selectAll') }}
                </div>
              </b-form-checkbox>
            </template>

            <template v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                v-model="indexSelectedTicketToRefund"
                :aria-describedby="ariaDescribedby"
                stacked
                name="flights"
              >
                <b-form-checkbox
                  v-for="(ticket, index) in listPaxsData"
                  :key="index"
                  :value="ticket"
                  class="mb-25"
                >
                  <div class="text-dark">
                    <span class="text-warning font-weight-bolder">{{ `${ticket.number} ` }}</span>
                    <small class="mr-25 font-weight-bolder">({{ ticket.paxId }})</small>
                    <span class="mr-50 font-weight-bolder">{{ `${ticket.lastName}/${ticket.firstName}` }}</span>
                    <span class="mr-50 text-dark font-weight-bold">{{ resolveSegmentsInEticket(ticket.segments) }}</span>
                    <span class="text-dark font-weight-bolder">{{ ticket.ticketType }} - {{ ticket.status }}</span>
                  </div>
                </b-form-checkbox>

                <!-- <div
                  v-for="(ticket, indexTicket) in listPaxsData"
                  :key="indexTicket"
                  class="d-flex-center justify-content-start py-0 pb-50"
                >
                  <b-form-checkbox
                    :value="ticket"
                  >
                    <div class="d-flex-center text-dark font-weight-bold font-medium-1">
                      <span class="text-warning font-weight-bolder mr-25">{{ `${ticket.number}` }}</span>
                      <span class="font-weight-bolder">
                        ({{ ticket.paxId }}) {{ `${ticket.lastName} ${ticket.firstName}` }} - {{ ticket.ticketType }} - {{ ticket.status }}
                      </span>
                    </div>
                  </b-form-checkbox>
                </div> -->
              </b-form-checkbox-group>
            </template>
          </b-form-group>
        </div>
      </b-card>

      <!-- =================================== -->
      <!-- <b-card>
        <div class="font-weight-bold mr-1 mb-1 text-dark">
          Chọn số vé:
        </div>

        <b-form-checkbox-group
          v-if="!isEmpty(paxsData)"
          id="checkbox-group-2"
          v-model="passengerSelected"
          name="flavour-2"
          stacked
          class="margin-checkbox-label"
        >
          <b-form-checkbox
            v-for="(passenger, index) in paxsData"
            :key="index"
            :value="passenger"
            class="mb-25"
          >
            <div class="text-dark">
              <span class="text-warning font-weight-bolder">{{ `${passenger.number} ` }}</span>
              <small class="mr-25 font-weight-bolder">({{ passenger.paxId }})</small>
              <span class="mr-50 font-weight-bolder">{{ `${passenger.lastName}/${passenger.firstName}` }}</span>
              <span class="text-dark font-weight-bold">{{ resolveSegmentsInEticket(passenger.segments) }}</span>
            </div>
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-card> -->

      <div class="mt-1">
        <b-alert
          show
          variant="warning"
          class="mb-0"
        >
          <div class="alert-body">
            Bạn phải chắc chắn nắm được điều kiện hoàn vé. Các trường hợp không
            chắc chắn hoặc vé có mua các dịch vụ kèm vui lòng liên hệ Booker.
          </div>
        </b-alert>
      </div>
    </b-modal>

    <!-- ANCHOR - MODAL REFUND TICKET -->
    <b-modal
      id="modal-modify-eticket-refund-ticket-price"
      :title="$t('reservation.refundTicket')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      no-close-on-backdrop
    >
      <!-- @show="showModalCheckPrice" -->
      <template #modal-footer="{close}">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-danger"
          class="center rounded-pill width-100"
          @click="close()"
        >
          {{ $t('reservation.close') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient mt-lg-25  border-0"
          pill
          :disabled="!isConfirm || !hasPrice"
          @click="submitRefundTicket"
        >
          <span class="align-middle">
            {{ $t('reservation.refundTicket') }}
          </span>
        </b-button>
      </template>
      <b-card>
        <b-overlay
          :show="loading"
          rounded="sm"
          no-fade
          variant="transparent"
          spinner-variant="info"
          opacity="0.85"
        >
          <b-row class="mb-sm-1 mb-md-25 flex-nowrap">
            <b-col md="6">
              Tổng tiền vé:
            </b-col>
            <b-col md="6">
              <span v-if="priceRefund && isNumber(priceRefund.subAmount)">{{ formatterAmount(priceRefund.subAmount) }}</span>
            </b-col>
          </b-row>
          <b-row class="mb-sm-1 mb-md-25 flex-nowrap">
            <b-col md="6">
              Phí hoàn:
            </b-col>
            <b-col md="6">
              <span v-if="priceRefund && isNumber(priceRefund.amount)">{{ getTotalRefundAmount(priceRefund) }}</span>
            </b-col>
          </b-row>
          <b-row class="mt-sm-1 mt-md-50 flex-nowrap">
            <b-col
              md="6"
              class="font-weight-bolder text-dark"
            >
              Tổng tiền hoàn:
            </b-col>
            <b-col md="6">
              <span
                v-if="priceRefund && isNumber(priceRefund.amount)"
                class="font-weight-bolder"
              >{{ formatterAmount(priceRefund.amount) }}</span>
            </b-col>
          </b-row>
        </b-overlay>
      </b-card>
      <IAmConfirm
        v-if="priceRefund"
        :is-confirm.sync="isConfirm"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BAlert,
  BCard,
  BButton,
  BRow,
  BCol,
  BOverlay,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormGroup,
} from 'bootstrap-vue'
import {
  ref, toRefs, watch,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import capitalize from 'lodash/capitalize'
import isNumber from 'lodash/isNumber'

import IAmConfirm from '@/components/IAmConfirm.vue'
import { formatCurrency, convertISODateTime } from '@/@core/utils/filter'
import store from '@/store'

import useReservationHandle from '@reservation/useReservationHandle'

import useToast from '@useToast'

export default {
  components: {
    BModal,
    BAlert,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormCheckboxGroup,
    BFormGroup,
    BFormCheckbox,
    BOverlay,

    IAmConfirm,
  },
  props: {
    reservationsData: {
      type: Object,
      required: true,
    },
    paxsData: {
      type: Array,
      required: true,
    },
  },
  setup(props, { root }) {
    const { toastError } = useToast()
    const { refundTicketAmount, refundTickets } = useReservationHandle()

    const bookingData = toRefs(props).reservationsData
    const listPaxsData = toRefs(props).paxsData

    const priceRefund = ref()
    const isConfirm = ref(false)
    const hasPrice = ref(false)
    const loading = ref(true)

    const indexSelectedTicketToRefund = ref([])

    // ANCHOR SELECT ALL
    const allSelected = ref(false)

    function toggleAll(checked) {
      indexSelectedTicketToRefund.value = checked
        ? listPaxsData.value.slice() : []
    }

    watch(indexSelectedTicketToRefund, newValue => {
      if (newValue.length === 0) {
        allSelected.value = false
      } else if (newValue.length === listPaxsData.value.length) {
        allSelected.value = true
      } else {
        allSelected.value = false
      }
    })

    // ANCHOR - CHECK PRICE ======================================
    function resolveSegmentsInEticket(segments) {
      const result = []

      if (!isEmpty(segments)) {
        segments.forEach(item => {
          result.push(`${convertISODateTime(item.departureDate, 'UTC').dayMonth}-${item.departure}${item.arrival}`)
        })
      }

      const dataReturn = !isEmpty(result) ? result.join(', ') : ''

      return dataReturn
    }

    function showModalRefundTicket() {
      indexSelectedTicketToRefund.value = []
    }

    function mergeItems(items) {
      const mergedItem = {
        amount: 0,
        subAmount: 0,
      }

      items.forEach(item => {
        mergedItem.amount += item.amount
        mergedItem.subAmount += item.subAmount
      })

      return mergedItem
    }

    function submitCheckPriceAmount() {
      isConfirm.value = false
      hasPrice.value = false
      loading.value = true

      const payload = {
        source: bookingData.value.source,
        pnrNumber: bookingData.value.bookingCode,
        ticketNumber: indexSelectedTicketToRefund.value[0].number,
        // paxId: props.ticketData.passengerPaxId,
        refundTickets: indexSelectedTicketToRefund.value.map(p => ({
          paxId: p.paxId,
          ticketNumber: p.number,
        })),
      }

      priceRefund.value = true

      store.dispatch('app/setLoading', true)

      refundTicketAmount(payload)
        .then(res => {
          priceRefund.value = mergeItems(res)
          hasPrice.value = true
          // console.log(res)
          this.$bvModal.show('modal-modify-eticket-refund-ticket-price')
        })
        .catch(error => {
          console.error(error)
          toastError({
            title: 'messagesList.error',
            content: 'Lỗi lấy giá hoàn vé, vui lòng thử lại!',
          })
          // this.$bvModal.hide('modal-modify-eticket-refund-ticket-price')
        })
        .finally(() => {
          store.dispatch('app/setLoading', false)
          loading.value = false
        })
    }

    function submitRefundTicket() {
      const payload = {
        source: bookingData.value.source,
        // ticketNumber: props.ticketData.number,
        // paxId: props.ticketData.passengerPaxId,
        pnrNumber: bookingData.value.bookingCode,
        agencyCode: bookingData.value.agency,
        paxCode: bookingData.value.paxContact.code,
        refundTickets: indexSelectedTicketToRefund.value.map(p => ({
          paxId: p.paxId,
          ticketNumber: p.number,
        })),
      }

      store.dispatch('app/setLoading', true)

      refundTickets(payload)
        .then(() => {
          Array.from(window.document.querySelectorAll('.modal.show'))
            .map(el => el.id)
            .forEach(id => {
              root.$root.$emit('bv::hide::modal', id)
            })
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          store.dispatch('app/setLoading', false)
        })
    }

    function formatterAmount(amount) {
      if (amount >= 0) return formatCurrency(amount) || amount
      return formatCurrency(amount * -1) || (amount * -1)
    }

    function getTotalRefundAmount(data) {
      const a = data.subAmount - data.amount
      if (a > 0) return formatCurrency(a) || a
      return formatCurrency(a * -1) || (a * -1)
    }

    return {
      loading,
      priceRefund,
      bookingData,
      isConfirm,
      hasPrice,
      allSelected,
      toggleAll,
      listPaxsData,
      indexSelectedTicketToRefund,

      isEmpty,
      capitalize,
      isNumber,
      formatCurrency,
      formatterAmount,
      submitRefundTicket,
      convertISODateTime,
      submitCheckPriceAmount,
      getTotalRefundAmount,
      resolveSegmentsInEticket,

      showModalRefundTicket,

    }
  },
}
</script>
