var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "modal-modify-eticket-exchange-ticket",
      "title": _vm.$t('reservation.changeTicket'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "show": _vm.showModalExchangeTicket
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill width-100",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient mt-lg-25  border-0",
          attrs: {
            "pill": "",
            "disabled": !(_vm.passengerSelected && _vm.passengerSelected.length) || !(_vm.segmentSelected && _vm.segmentSelected.length)
          },
          on: {
            "click": _vm.submitCheckPriceAmount
          }
        }, [_c('span', {
          staticClass: "align-middle"
        }, [_vm._v(_vm._s(_vm.$t('reservation.historyActions.CALCULATE_PRICE')))])])];
      }
    }])
  }, [_c('b-card', [_c('div', {
    staticClass: "font-weight-bold mr-1 mb-1 text-dark font-small-4"
  }, [_vm._v(" Chọn số vé: ")]), !_vm.isEmpty(_vm.paxsData) ? _c('b-form-checkbox-group', {
    staticClass: "margin-checkbox-label",
    attrs: {
      "id": "checkbox-group-2",
      "name": "flavour-2",
      "stacked": ""
    },
    model: {
      value: _vm.passengerSelected,
      callback: function callback($$v) {
        _vm.passengerSelected = $$v;
      },
      expression: "passengerSelected"
    }
  }, _vm._l(_vm.paxsData, function (passenger, index) {
    return _c('b-form-checkbox', {
      key: index,
      staticClass: "mb-25",
      attrs: {
        "value": passenger
      }
    }, [_c('div', {
      staticClass: "text-dark"
    }, [_c('span', {
      staticClass: "text-warning font-weight-bolder"
    }, [_vm._v(_vm._s("".concat(passenger.number, " ")))]), _c('small', {
      staticClass: "mr-25 font-weight-bolder"
    }, [_vm._v("(" + _vm._s(passenger.paxId) + ")")]), _c('span', {
      staticClass: "mr-50 font-weight-bolder"
    }, [_vm._v(_vm._s("".concat(passenger.lastName, "/").concat(passenger.firstName)))]), _c('span', {
      staticClass: "text-dark font-weight-bold"
    }, [_vm._v(_vm._s(_vm.resolveSegmentsInEticket(passenger.segments)))])])]);
  }), 1) : _vm._e()], 1), _c('b-card', [_c('div', {
    staticClass: "font-weight-bold mr-1 mb-1 font-small-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.chooseSegment')) + ": ")]), !_vm.isEmpty(_vm.segmentArray) ? _c('b-form-checkbox-group', {
    staticClass: "margin-checkbox-label",
    attrs: {
      "id": "checkbox-group-2",
      "name": "flavour-2",
      "stacked": ""
    },
    model: {
      value: _vm.segmentSelected,
      callback: function callback($$v) {
        _vm.segmentSelected = $$v;
      },
      expression: "segmentSelected"
    }
  }, _vm._l(_vm.segmentArray, function (segment, index) {
    return _c('b-form-checkbox', {
      key: index,
      staticClass: "mb-25",
      attrs: {
        "value": segment.segmentId
      }
    }, [_c('div', {
      staticClass: "font-weight-bolder text-dark"
    }, [_vm._v(" " + _vm._s(_vm.convertShortTrip(segment)) + " ")])]);
  }), 1) : _vm._e()], 1), _c('div', {
    staticClass: "mt-1"
  }, [_c('b-alert', {
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" Bạn phải chắc chắn nắm được điều kiện đổi vé. Các trường hợp không chắc chắn hoặc vé có mua các dịch vụ kèm vui lòng liên hệ Booker. ")])])], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-modify-eticket-exchange-ticket-price",
      "title": _vm.$t('reservation.confirm'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "body-class": "px-25",
      "centered": "",
      "size": "lg",
      "no-close-on-backdrop": ""
    },
    on: {
      "hide": _vm.handleHideModalExchangeTicketPrice
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref2) {
        var close = _ref2.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill",
          attrs: {
            "size": "md",
            "variant": "outline-danger"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient mt-lg-25  border-0",
          attrs: {
            "pill": "",
            "disabled": !_vm.isConfirm || !_vm.hasPrice
          },
          on: {
            "click": _vm.submitExchangeTicket
          }
        }, [_c('span', {
          staticClass: "align-middle"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.changeTicket')) + " ")])])];
      }
    }])
  }, [_vm.priceExchange && _vm.priceExchange.subAmount < 0 ? _c('div', [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-alert', {
    staticClass: "mb-0 px-1 py-50",
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_vm._v(" Không đủ điều kiện xuất đổi vé, kiểm tra lại thông tin vé, chặng bay đã chọn. ")])], 1)], 1) : _c('div', [_c('b-card', {
    attrs: {
      "body-class": "p-75"
    }
  }, [_c('p', {
    staticClass: "text-dark font-weight-bolder"
  }, [_vm._v(" Vui lòng kiểm tra lại thông tin hành trình trước khi thay đổi ")]), _c('div', {
    staticClass: "mb-1"
  }, [_c('p', {
    staticClass: "text-danger font-weight-bolder font-medium-4 mb-50"
  }, [_vm._v(" Hành trình cũ ")]), _vm.priceExchange && _vm.priceExchange.exchFlights ? _c('b-table', {
    staticClass: "border-danger rounded-lg",
    attrs: {
      "bordered": "",
      "responsive": "",
      "small": "",
      "items": _vm.priceExchange.exchFlights,
      "fields": ['seg', 'number', 'class', 'flight', 'date', 'fareBasic', 'status', 'totalTax', 'netPrice'],
      "thead-tr-class": "text-nowrap text-center",
      "tbody-tr-class": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "cell(seg)",
      fn: function fn(data) {
        return [_c('div', [_vm._v(" " + _vm._s(data.index + 1) + " ")])];
      }
    }, {
      key: "cell(number)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(item.operating) + _vm._s(item.flightNumber) + " ")])];
      }
    }, {
      key: "cell(class)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', [_vm._v(" " + _vm._s(item.bookingClass) + " ")])];
      }
    }, {
      key: "cell(flight)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(item.departure) + "-" + _vm._s(item.arrival) + " ")])];
      }
    }, {
      key: "cell(date)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.departureDate).dateTime) + " ")])];
      }
    }, {
      key: "cell(fareBasic)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('div', [_vm._v(" " + _vm._s(item.fareBasic) + " ")])];
      }
    }, {
      key: "cell(status)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('div', [_vm._v(" " + _vm._s(_vm.resolveStatusByFlown(item.flown)) + " ")])];
      }
    }, {
      key: "cell(totalTax)",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('div', [_vm._v(" " + _vm._s(_vm.formatCurrency(item.totalTax)) + " ")])];
      }
    }, {
      key: "cell(netPrice)",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('div', [_vm._v(" " + _vm._s(_vm.formatCurrency(item.netPrice)) + " ")])];
      }
    }], null, false, 1340716430)
  }) : _vm._e()], 1), _c('div', {
    staticClass: "mb-2 mt-1"
  }, [_c('p', {
    staticClass: "text-success font-weight-bolder font-medium-4 mb-50"
  }, [_vm._v(" Hành trình mới ")]), _vm.priceExchange && _vm.priceExchange.newFlights ? _c('b-table', {
    staticClass: "border-success rounded-lg",
    attrs: {
      "bordered": "",
      "responsive": "",
      "small": "",
      "items": _vm.priceExchange.newFlights,
      "fields": ['seg', 'number', 'class', 'flight', 'date', 'fareBasic', 'status', 'totalTax', 'netPrice'],
      "thead-tr-class": "text-nowrap text-center",
      "tbody-tr-class": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "cell(seg)",
      fn: function fn(data) {
        return [_c('div', [_vm._v(" " + _vm._s(data.index + 1) + " ")])];
      }
    }, {
      key: "cell(number)",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(item.operating) + _vm._s(item.flightNumber) + " ")])];
      }
    }, {
      key: "cell(class)",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_c('div', [_vm._v(" " + _vm._s(item.bookingClass) + " ")])];
      }
    }, {
      key: "cell(flight)",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(item.departure) + "-" + _vm._s(item.arrival) + " ")])];
      }
    }, {
      key: "cell(date)",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_c('div', [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.departureDate, 'UTC').dateTime) + " ")])];
      }
    }, {
      key: "cell(fareBasic)",
      fn: function fn(_ref15) {
        var item = _ref15.item;
        return [_c('div', [_vm._v(" " + _vm._s(item.fareBasic) + " ")])];
      }
    }, {
      key: "cell(status)",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [_c('div', [_vm._v(" " + _vm._s(_vm.resolveStatusByFlown(item.flown)) + " ")])];
      }
    }, {
      key: "cell(totalTax)",
      fn: function fn(_ref17) {
        var item = _ref17.item;
        return [_c('div', [_vm._v(" " + _vm._s(_vm.formatCurrency(item.totalTax)) + " ")])];
      }
    }, {
      key: "cell(netPrice)",
      fn: function fn(_ref18) {
        var item = _ref18.item;
        return [_c('div', [_vm._v(" " + _vm._s(_vm.formatCurrency(item.netPrice)) + " ")])];
      }
    }], null, false, 2972537312)
  }) : _vm._e()], 1), _c('div', [_c('b-row', {
    staticClass: "mb-sm-1 mb-md-25 justify-content-end",
    class: _vm.isMobileView ? 'flex-nowrap' : ''
  }, [_c('b-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "8",
      "md": "6"
    }
  }, [_vm._v(" Tổng tiền chênh: ")]), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "4"
    }
  }, [_vm.priceExchange && _vm.priceExchange.subAmount >= 0 ? _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.formatterAmount(_vm.priceExchange.subAmount)) + " ")]) : _c('span', [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  })], 1)])], 1), _c('b-row', {
    staticClass: "mb-sm-1 mb-md-25 justify-content-end",
    class: _vm.isMobileView ? 'flex-nowrap' : ''
  }, [_c('b-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "8",
      "md": "6"
    }
  }, [_vm._v(" Phí đổi: ")]), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "4"
    }
  }, [_vm.priceExchange && _vm.priceExchange.amount >= 0 ? _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.getTotalExchangeAmount(_vm.priceExchange) || 0) + " ")]) : _c('span', [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  })], 1)])], 1), _c('b-row', {
    staticClass: "mt-sm-1 mt-md-25 justify-content-end",
    class: _vm.isMobileView ? 'flex-nowrap' : ''
  }, [_c('b-col', {
    staticClass: "font-weight-bolder text-dark text-right",
    attrs: {
      "cols": "8",
      "md": "6"
    }
  }, [_vm._v(" Tổng tiền phải trả: ")]), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "4"
    }
  }, [_vm.priceExchange && _vm.priceExchange.amount >= 0 ? _c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.formatterAmount(_vm.priceExchange.amount) || 0) + " ")]) : _c('span', [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  })], 1)])], 1)], 1)]), _vm.priceExchange ? _c('IAmConfirm', {
    attrs: {
      "is-confirm": _vm.isConfirm
    },
    on: {
      "update:isConfirm": function updateIsConfirm($event) {
        _vm.isConfirm = $event;
      },
      "update:is-confirm": function updateIsConfirm($event) {
        _vm.isConfirm = $event;
      }
    }
  }) : _vm._e()], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }